//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'Slider',
  components: {
    VueSlickCarousel,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        arrows: false,
        pauseOnHover: true,
        initialSlide: 0,
        responsive: [],
      },
    }
  },
}
